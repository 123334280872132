<template>
  <div class="graceful">
    <div class="graceful-main">
      <div class="swiper-container graceful-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide graceful-slide">
            <div class="swiper-slide-content">
              <div class="graceful-img">
                <img src="@/assets/image/about/p1.jpg" alt="" />
              </div>
              <div class="graceful-content p-4">
                <div class="title">
                  <img src="@/assets/image/case/16.png" alt="" />
                </div>
                <div class="desc mt-4">
                  与晨鸣集团的合作始于2017年，当初因晨鸣集团急需资质来承接比较大的项目，后经过我们的方案提议，在原有的资质上做了增项，由于效率高，专业程度高，办理的增项一次性通过，让晨鸣能把大型项目收括囊中。
                </div>
                <div class="info">
                  <div class="name">晨鸣集团</div>
                  <img src="@/assets/image/design/060.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-slide graceful-slide">
            <div class="swiper-slide-content">
              <div class="graceful-img">
                <img src="@/assets/image/about/p2.jpg" alt="" />
              </div>
              <div class="graceful-content p-4">
                <div class="title">
                  <img src="@/assets/image/case/15.png" alt="" />
                </div>
                <div class="desc mt-4">
                  与酒钢集团的合作已经好几年了，他们选择了相信优普道，相信我们的专业和效率，集团在发展过程中新增了许多新的业务，业务所需的资质也交托给了我们。因此酒钢集团现在发展得越来越好，业务越做广。
                </div>
                <div class="info">
                  <div class="name">酒钢集团</div>
                  <img src="@/assets/image/design/060.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-slide graceful-slide">
            <div class="swiper-slide-content">
              <div class="graceful-img">
                <img src="@/assets/image/about/p3.jpg" alt="" />
              </div>
              <div class="graceful-content p-4">
                <div class="title">
                  <img src="@/assets/image/case/1.png" alt="" />
                </div>
                <div class="desc mt-4">
                  跟绿地集团的接触机缘来自一位曾经的客户的推荐，后来绿地集团经过长时间对我们的深入了解，发展我们优普道是一家专业程度很高的建筑咨询公司，有着多年从业经验，口碑也是非常好，因为与我们签订了战略合作合同，后续将在更多领域发展合作。
                </div>
                <div class="info">
                  <div class="name">绿地集团</div>
                  <img src="@/assets/image/design/060.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-slide graceful-slide">
            <div class="swiper-slide-content">
              <div class="graceful-img">
                <img src="@/assets/image/about/p4.jpg" alt="" />
              </div>
              <div class="graceful-content p-4">
                <div class="title">
                  <img src="@/assets/image/case/10.png" alt="" />
                </div>
                <div class="desc mt-4">
                  这是一位老朋友了，凡是与资质和建工人才有关的业务，一律制定交给优普道来办理，也因此，在这个版块里他们能省出更多人力物力，使整个集团架构显得更加合理。
                </div>
                <div class="info">
                  <div class="name">南昌广电网络</div>
                  <img src="@/assets/image/design/060.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-slide graceful-slide">
            <div class="swiper-slide-content">
              <div class="graceful-img">
                <img src="@/assets/image/about/p5.jpg" alt="" />
              </div>
              <div class="graceful-content p-4">
                <div class="title">
                  <img src="@/assets/image/case/5.png" alt="" />
                </div>
                <div class="desc mt-4">
                  与南昌水业集团的姻缘是在一个建筑行业研讨会上，通过简短的会议，南昌水业集团的高管发展优普道的专业程度非常高，因此就有了之后的合作了。也因为这次合作，使得南昌水业集团能在短时间内承接许多不同领域里的大项目。为往后的业务发展提供的坚实的基础。
                </div>
                <div class="info">
                  <div class="name">南昌水业集团</div>
                  <img src="../../assets/image/design/060.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    hadFrom: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    initGracefulSwiper() {
      var gracefulSwiper = new Swiper(".graceful-container", {
        loop: true,
        autoplay: 2000,
        nextButton: ".swiper-button-next",
        prevButton: ".swiper-button-prev",
      });
    },
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  mounted() {
    this.initGracefulSwiper();
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/public.scss";
</style>
